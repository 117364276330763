@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #000;
    color: #fff;
    font-family: 'Roboto';
}

* {
    box-sizing: border-box;
}

#root{
    width: 100%;
    height: 100%;
}

.picker {
    position: absolute !important;
    top: 30%;
    left: 30%;
    width: 90px !important;
    height: 90px !important;
  }